
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.fiscal_year')"
              label-for="fiscal_year_id"
              >
              <b-form-select
              plain
              v-model="search.fiscal_year_id"
              :options="fiscalYearList"
              id="fiscal_year_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </template>
      </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.subsidy_requisition_and_approval') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                         <template v-slot:cell(status_value)="data">
                                            {{ getStatus(data.item.status_value) }}
                                         </template>
                                         <template v-slot:cell(total_subsidy_amount)="data">
                                            {{ $n(data.item.total_subsidy_amount, { useGrouping: false }) }}
                                         </template>
                                         <template v-slot:cell(verfied)="data">
                                            <span class="badge badge-success" v-if="data.item.verfied == 1">{{$t('bsri_demandDIS.done')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('bsri_demandDIS.not_done')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button class="mr-1" title="View" v-b-modal.modal-details variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                            <b-button v-if="data.item.status_value === 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="allocation(data.item)"><i class="ri-arrow-right-line"></i></b-button>
                                            <b-button v-if="$store.state.Auth.authUser.ssoLogin"  style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="sendToNothi(data.item)">
                                              <i class="far fa-check-square m-0"></i> {{$t('elearning_venue.forward_to_nothi')}}
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-details" size="xl" :title="$t('bsri_demandDIS.subsidy_requisition_and_approval')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :fiscalYearId="fiscalYearId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :item="item" :fiscalYearId="fiscalYearId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Details'
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyRequisitionAndApprovalListApi, pendingNothiSubsidyRequisitionAndApprovalList, subsidyRequisitionAndApprovalMillWiseSubAmount } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0'
      },
      fiscalYearId: '',
      rows: [],
      item: '',
      editItemId: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.subsidy_requisition_and_approval') : this.$t('bsri_demandDIS.subsidy_requisition_and_approval') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.requisition_amount'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.status'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'total_subsidy_amount' },
          { key: 'status_value' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'total_subsidy_amount' },
          { key: 'status_value' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    // loadingState: function (newVal, oldVal) {
    //   if (newVal) {
    //     this.loadData()
    //   }
    // }
  },
  created () {
   this.loadData()
  },
  methods: {
    // ==========eNothi Start===================
    async sendToNothi (item) {
      // ---------------------------
      this.loading = true
      this.showData = true
      const resultPrm = {
        fiscal_year: '',
        approvedList: [],
        approvedMillList: [],
        culMethod: [],
        policyLandGrandTotal: [],
        verifiedLandGrandTotal: [],
        amountGrandTotal: [],
        allocated_amount: '',
        actual_amount: '',
        percentage: ''
      }
      const params = { fiscal_year_id: item.fiscal_year_id }
         await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyRequisitionAndApprovalMillWiseSubAmount, params)
        .then(response => {
          if (response.success) {
              if (this.search.fiscal_year_id) {
                const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
                resultPrm.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
              }

              resultPrm.approvedList = response.approvedList
              resultPrm.approvedMillList = response.approvedMillList
              resultPrm.culMethod = response.culMethod
              resultPrm.policyLandGrandTotal = response.policyLandGrandTotal
              resultPrm.verifiedLandGrandTotal = response.verifiedLandGrandTotal
              resultPrm.amountGrandTotal = response.amountGrandTotal

              resultPrm.allocated_amount = response.amountGrandTotal
              resultPrm.actual_amount = response.amountGrandTotal
              resultPrm.percentage = 100
              this.toggleStatusCustom2(incentiveGrantServiceBaseUrl, pendingNothiSubsidyRequisitionAndApprovalList, resultPrm)
              this.loading = false
          } else {
            this.approvedList = []
            this.approvedMillList = []
            this.culMethod = []
          }
        })
        this.loading = false
      // ---------------------------
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        // RestApi.getData(baseUrl, uri, item).then(response => {
        RestApi.postData(baseUrl, uri, item).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
    // ==========eNothi End=====================
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
      this.fiscalYearId = item.fiscal_year_id
    },
    allocation (item) {
      this.editItemId = item.id
      this.fiscalYearId = item.fiscal_year_id
    },
    async loadData () {
      this.loadingState = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, subsidyRequisitionAndApprovalListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.loadingState = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = { fiscal_year_en: fiscalYearObj?.text_en, fiscal_year_bn: fiscalYearObj?.text_bn }
        return Object.assign({}, item, fiscalYearData)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('bsri_demandDIS.pending')
      } else if (status === 2) {
        return this.$t('bsri_demandDIS.forward')
      } else if (status === 3) {
        return this.$t('bsri_demandDIS.approved')
      } else if (status === 4) {
        return this.$t('bsri_demandDIS.disburse')
      }
    }
  }
}
</script>
